<script setup>
import {defineAsyncComponent} from "vue";

const props = defineProps({
  name: String,
  settings: Object,
  data: Object
})

const margins = ref({})
if (props.settings?.margins) {
  margins.value = JSON.parse(props.settings.margins)
  console.log(margins.value)
}

const pluginComponent = defineAsyncComponent({
  loader: () => import(`~/components/plugins/${props.name}/${props.name}.vue`),
  onError(error, retry, fail, attempts) {
    console.error(`Error loading Plugin Component ${attempts} спроб:`, error)
    fail()
  }
})

</script>

<template>
  <div :class="{container:settings?.inContainer}"
       :style="{marginLeft:margins.left,marginRight:margins.right,marginTop:margins.top,marginBottom:margins.bottom}">
    <component :inContainer="settings?.inContainer" :data="data" v-if="pluginComponent" :is="pluginComponent"/>
  </div>
</template>
