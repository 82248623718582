<script setup lang="ts">
import GreyBlock from "@/components/templates/GreyBlock.vue";
import {useRoute} from "vue-router";

const props = defineProps({
  shiftedBackground: {
    type: Boolean,
    default: false
  }
})

const route= useRoute();
</script>

<template>
  <div class="g-32 shorter-gap">
    <div id="start" v-if="$slots.start" class="plugin-container g-32">
      <slot name="start"/>
    </div>
    <div id="start-content" v-if="$slots['start-content']" class="plugin-container g-32">
      <slot name="start-content"/>
    </div>
    <div id="header-group" v-if="$slots['header-group']" class="plugin-container g-32">
      <slot name="header-group"/>
    </div>

    <div class="g-32">
      <slot name="ordinary-content"/>

      <div class="parent-container g-32"
           v-if="$slots['header'] || $slots['full-header'] || $slots['grid'] || $slots['white-footer'] || $slots['footer'] || $slots['out_footer']">
        <div class="short-gap-containers g-32" style="z-index: 2;" v-if="$slots['header'] || $slots['full-header'] || $slots['grid']">
          <div class="container" v-if="$slots['header']">
            <div id="header" class="plugin-container g-32">
              <slot name="header"/>
            </div>
          </div>
          <div id="full-header" v-if="$slots['full-header']" class="plugin-container g-32">
            <slot name="full-header"/>
          </div>
          <div class="container" v-if="$slots['grid']">
            <div id="grid" class="plugin-container g-32">
              <slot name="grid"/>
            </div>
          </div>
        </div>

        <br v-if="parseInt(route?.query?.page ?? 1) >= 2">

        <div class="big-gaps" v-if="parseInt(route?.query?.page ?? 1) < 2 && ($slots['white-footer']  || $slots['footer'] || $slots['out_footer'])">
          <div class="wide-footer" v-if="$slots['white-footer']">
            <div class="container">
              <div id="white-footer" class="g-96">
                <slot name="white-footer"/>
              </div>
            </div>
          </div>

          <GreyBlock :class="{'shifted-background':shiftedBackground}" v-if="$slots.footer || $slots.out_footer">
            <div class="g-96 grey-block-container">
              <div class="container full-container product-container" v-if="$slots.footer">
                <div id="footer">
                  <slot name="footer"/>
                </div>
              </div>

              <div id="out_footer" class="g-96" v-if="$slots['out_footer']">
                <slot name="out_footer"/>
              </div>
            </div>
          </GreyBlock>
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container-margin {
  margin-top: 32px;

  @include smd {
    margin-top: 8px;
  }
}

.grey-block-container {
  padding-top: 60px;
}

#footer {
  gap: 96px;

  @include smd {
    gap: 64px;
  }
}

.big-gaps {
  gap: 96px;

  @include smd {
    gap: 64px;
  }
}

#start-content {
  margin-top: -24px;

  @include smd {
    margin-top: 0;
  }
}

.shifted-background {
  margin-top: -300px;
  padding-top: 300px;
}
</style>
